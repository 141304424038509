import React from 'react'
import { SiteProvider } from './src/common/Context/app'
import "./src/common/styles/app.css";
import "animate.css"
import { AnimatePresence } from "framer-motion"

export const wrapRootElement = ( { element }) => (
  <SiteProvider>
    <AnimatePresence>
      {element}
    </AnimatePresence>
  </SiteProvider>
)

const waitForElement = (querySelector, timeout) => {
  return new Promise((resolve, reject)=>{
    let timer = false;
    const observer = new MutationObserver(()=>{
      const element = document.querySelector(querySelector);
      if(element){
        observer.disconnect();
        if(timer !== false) clearTimeout(timer);
        return resolve(element);
      }
    });
    observer.observe(document.body, {
      childList: true, 
      subtree: true
    });
    if(timeout) timer = setTimeout(()=>{
      observer.disconnect();
      reject();
    }, timeout);
  });
}

const anchorScroll = (location) => {
  // Check for location so build does not fail
  if (location?.hash) {
    waitForElement(location?.hash, 2000).then((element)=>{
      element?.scrollIntoView?.({ behavior: 'smooth', block: 'start' });
    });
  }
}

export const onRouteUpdate = ({location}) => {
  anchorScroll(location);
  return true;
};
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  anchorScroll(location);
  return true;
}
